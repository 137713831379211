import ApplicationController from '../application_controller';

export default class FilterClickedController extends ApplicationController {
  static values = {
    location: String,
    accountType: String,
  }

  connect() {
    this.currentTarget = null;
    this.target = null;
  }

  track(event) {
    this.currentTarget = event.currentTarget;
    this.target = event.target;
    this.trackEvent();
  }

  trackEvent() {
    if (!window.rudderanalytics) {
      console.log('RS not initialized', this.properties());
      return;
    }

    window.rudderanalytics.track(
      'Filter Clicked',
      this.properties(),
    );
  }

  properties() {
    const { currentTarget, locationValue, accountTypeValue } = this;
    const { filterName } = currentTarget.dataset;
    const { requestAction, requestController } = window.TeePublic;
    const filterLabel = this.filterLabel();

    return {
      account_type: accountTypeValue,
      filter_name: filterName,
      filter_option_label: filterLabel,
      location: locationValue,
      request_action: requestAction,
      request_controller: requestController,
    }
  }

  filterLabel() {
    if (this.productConfigEvent()) return this.productConfigFilterLabel();

    const filter = this.currentTarget;
    const { tagName, dataset } = filter;
    return tagName === 'SELECT'
      ? filter.selectedOptions[0].textContent
      : dataset.filterOptionLabel || filter.innerText;
  }

  productConfigEvent() {
    return this.locationValue === 'product_attributes';
  }

  productConfigFilterLabel() {
    const { target } = this;
    const { tagName, value } = target;
    const { inputType } = target.dataset;

    if (tagName === 'SELECT') return target.selectedOptions[0].textContent;

    if (inputType === 'increment') {
      return target.parentElement.querySelector('input[type="number"]').value;
    }

    if (target.type === 'number') return value;

    const labelElement = document.querySelector(`label[for="${this.target.id}"]`);
    return labelElement.title || labelElement.textContent.trim();
  }
}
