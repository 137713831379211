import ApplicationController from '../application_controller';
import Fetchers from 'core/fetchers';

export default class extends ApplicationController {
  static classes = [ 'hidden', 'success', 'error' ];
  static outlets = [ 'checkout--checkout-2024' ];
  static targets = [ 'collapsed', 'expanded', 'couponCode', 'couponCodeWrapper', 'couponForm', 'success', 'successMessage', 'error', 'submitButton' ];

  toggle() {
    const targetElements = [this.collapsedTarget, this.expandedTarget];
    const [elementToHide, elementToShow] =
      this.collapsed() ? targetElements : targetElements.reverse();
    elementToHide.classList.add(this.hiddenClass);
    elementToShow.classList.remove(this.hiddenClass);
  }

  collapsed() {
    return this.expandedTarget.classList.contains(this.hiddenClass);
  }

  submit() {
    const { value: couponCode } = this.couponCodeTarget;
    if (!couponCode) {
      this.errorTarget.innerText = 'You must enter a coupon code.';
      this.couponCodeWrapperTarget.classList.add(this.errorClass);
      this.submitButtonTarget.blur();
      return;
    }

    Fetchers.fetchJSON(`coupon_codes/validation/${couponCode}`, 'GET', null)
      .then((response) => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.message === 'valid') {
          const { value: couponCode } = this.couponCodeTarget;
          this.couponCodeWrapperTarget.classList.remove(this.errorClass);
          this.couponFormTarget.classList.add(this.hiddenClass);
          this.successMessageTarget.innerText = couponCode;
          this.successTarget.classList.remove(this.hiddenClass);
          this.updateSidebar();
        } else {
          this.errorTarget.innerText = parsedResponse.error[0];
          this.couponCodeWrapperTarget.classList.add(this.errorClass);
          this.submitButtonTarget.blur();
        }
      })
      .catch((err) => console.log(err));
  }

  clearCoupon() {
    Fetchers.fetchJSON('checkout/remove_coupon', 'DELETE')
      .then((response) => {
        if (response.status < 400) {
          this.successTarget.classList.add(this.hiddenClass);
          this.couponFormTarget.classList.remove(this.hiddenClass);
          this.updateSidebar();
          this.couponCodeTarget.value = '';
          this.toggle();
        } else {
          console.log('Couldn\'t remove coupon.');
        }
      })
  }

  updateSidebar() {
    this.checkoutCheckout2024Outlet.openLoadingOverlay();
    this.checkoutCheckout2024Outlet.fetchData(
      this.checkoutCheckout2024Outlet.urlValue,
      (data) => {
        this.checkoutCheckout2024Outlet.updateSidebar(data);
        this.checkoutCheckout2024Outlet.closeLoadingOverlay();
      },
      ['sidebar'],
      'PUT',
    );
  }
}
