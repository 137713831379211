import ApplicationController from '../application_controller';
import Fetchers from 'core/fetchers';

export default class extends ApplicationController {
  static targets = ['validatedAddress', 'address', 'modalContent', 'fieldset', 'button', 'paymentSection', 'hiddenField'];

  static values = { validatedAddress: Object };

  static outlets = ['containers--dialog-container', 'checkout--checkout-2024', 'checkout--validity--fieldset'];

  beginAddressVerification() {
    this.updateFieldsetValidityValues()
    this.forceValidationOfShippingAndContactInputs()

    if (!this.shipAddressAndContactInfoValid()) {
      this.focusFirstInvalidShippingOrContactField()
      return;
    }

    if (this.addressCanBeValidated()) {
      this.fetchModalData();
    } else {
      this.savedAddressUpdates();
      this.beginPaymentPhase();
    }
  }

  savedAddressUpdates() {
    this.validatedAddressValue = this.hiddenFieldTarget.value = this.checkoutCheckout2024Outlet.verifiedSavedAddress();
  }

  beginPaymentPhase() {
    this.collapseContactAndShippingFieldsets();
    this.checkoutCheckout2024Outlet.updateSameAsShippingRadioText()
    this.changePaymentSectionVisibility(true);
    this.changeValidateButtonVisibility(false);
    this.changePlaceOrderButtonVisibility(true);
    this.closeDialogContainer();
    this.scrollToShippingSection();
  }

  confirmAddress(e) {
    e.preventDefault()
    this.setValidatedAddress();
    this.setHiddenField();
    this.beginPaymentPhase();
  }

  continue(e) {
    e.preventDefault()
    this.beginPaymentPhase();
  }

  abort(e) {
    e.preventDefault()
    this.containersDialogContainerOutlet.close();
  }

  restartAddressValidationFlow() {
    this.changePaymentSectionVisibility(false);
    this.changePlaceOrderButtonVisibility(false);
    this.changeValidateButtonVisibility(true);
    this.expandContactAndShippingFieldsets();
  }

  addressCanBeValidated() {
    return !this.checkoutCheckout2024Outlet.hasVerifiedSavedAddressSelected() &&
      ['United States', 'Canada'].includes(this.checkoutCheckout2024Outlet.shipCountryName())
  }

  shipAddressAndContactInfoValid() {
    return this.checkoutValidityFieldsetOutlets
      .every(fieldsetOutlet => fieldsetOutlet.valid());
  }

  focusFirstInvalidShippingOrContactField() {
    this.checkoutCheckout2024Outlet.checkoutValidityBaseOutlets.every((outlet) => {
      if (outlet.valid()) return true;

      outlet.fieldTarget.focus();
      return false;
    });
  }

  forceValidationOfShippingAndContactInputs() {
    this.checkoutCheckout2024Outlet.checkoutValidityBaseOutlets.forEach(outlet => {
      if (['contact-info', 'shipping-address'].includes(outlet.parentFieldset())) outlet.updateValidity();
    });
  }

  changePaymentSectionVisibility(show = true) {
    this.paymentSectionTargets.forEach(outlet => {
      if (show) {
        outlet.classList.remove('hide')
      } else {
        outlet.classList.add('hide')
      }
    })
  }

  changeValidateButtonVisibility(show = true) {
    if (show) {
      this.buttonTarget.classList.remove('hide')
    } else {
      this.buttonTarget.classList.add('hide')
    }
  }

  changePlaceOrderButtonVisibility(show = true) {
    if (show) {
      this.checkoutCheckout2024Outlet.placeOrderButtonTarget.classList.remove('hidden')
    } else {
      this.checkoutCheckout2024Outlet.placeOrderButtonTarget.classList.add('hidden')
    }
  }

  closeDialogContainer() {
    if (this.addressCanBeValidated()) this.containersDialogContainerOutlet.close();
  }

  updateFieldsetValidityValues() {
    this.checkoutValidityFieldsetOutlets.forEach((outlet) => {
      outlet.updateValidity();
    });
  }

  collapseContactAndShippingFieldsets() {
    this.checkoutValidityFieldsetOutlets.forEach((outlet) => {
      outlet.collapse();
    });
  }

  expandContactAndShippingFieldsets() {
    this.checkoutValidityFieldsetOutlets.forEach((outlet) => {
      if (['checkout[ship_firstname]', 'checkout[shipping_method_id]'].includes(outlet.firstFieldName())) {
        outlet.expand();
      }
    });
  }

  scrollToShippingSection() {
    this.paymentSectionTarget.scrollIntoView();
  }

  fetchModalData() {
    this.checkoutCheckout2024Outlet.openLoadingOverlay();

    Fetchers.fetchJSON(
      '/checkout/address_validation',
      'POST',
      JSON.stringify(this.checkoutCheckout2024Outlet.serializeFormData()),
    )
      .then(Fetchers.parseResponse)
      .then((res) => {
        const data = res.data;
        this.openModal(res.data)
      })
      .catch((error) => {
        alert('error')
        this.checkoutCheckout2024Outlet.closeLoadingOverlay();
      });
  }

  openModal(data) {
    this.checkoutCheckout2024Outlet.closeLoadingOverlay();
    this.modalContentTarget.innerHTML = data.html
    this.validatedAddressValue = data.avalara_data;
    this.containersDialogContainerOutlet.open();
  }

  setValidatedAddress() {
    const address = this.validatedAddressValue.validated_address
    this.checkoutCheckout2024Outlet.shipAddress1Target.value = address.line1;
    this.checkoutCheckout2024Outlet.shipAddress2Target.value = address.line2;
    this.checkoutCheckout2024Outlet.shipCityTarget.value = address.city;
    this.checkoutCheckout2024Outlet.shipStateTarget.value = address.region;
    this.checkoutCheckout2024Outlet.shipZipCodeTarget.value = address.postalCode;
    this.checkoutCheckout2024Outlet.shipCountryTarget.value = address.countryId;
  }

  setHiddenField() {
    this.hiddenFieldTarget.value = JSON.stringify(this.validatedAddressValue.original_payload);
  }
}
