import ApplicationController from '../application_controller'

const INPUT_FIELD_EVENT_TYPES = ['focus', 'blur', 'input']
export default class HeaderController extends ApplicationController {
  static targets = ['header', 'searchField', 'searchFieldWrapper', 'placeholderWrapper']

  connect() {
    this.initSearchField();
  }

  disconnect() {
    INPUT_FIELD_EVENT_TYPES.forEach(action => {
      if (action === 'focus' || action === 'blur') {
        this.searchFieldTarget.removeEventListener(action, this.handleInteraction);
      } else if (action === 'input') {
        this.searchFieldTarget.removeEventListener(action, this.handleSearchFieldInput);
      }
    });
  }

  handleInteraction() {
    this.headerTarget.classList.toggle('active-search');
    this.searchFieldWrapperTarget.classList.toggle('inactive');
  }

  handleSearchFieldInput(input) {
    // If input is not empty, hide placeholderWrapper
    if (input) {
      this.placeholderWrapperTarget.classList.add('hide');
    } else {
      this.placeholderWrapperTarget.classList.remove('hide');
    }
  }

  initSearchField() {
    INPUT_FIELD_EVENT_TYPES.forEach(action => {
      this.searchFieldTarget.addEventListener(action, (event) => {
        if (action === 'focus' || action === 'blur') {
          this.handleInteraction();
        } else if (action === 'input') {
          this.handleSearchFieldInput(event.target.value);
        }
      })
    })
  }
}
