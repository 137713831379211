import fetchJSON from './fetch_json'
import fetchJs from './fetch_js'

const parseResponse = response => (response.json().then(data => ({
  data,
  status: response.status,
})));

const Fetchers = {
  fetchJSON,
  fetchJs,
  parseResponse,
}

export default Fetchers;
