const storeContextSessionId = () => {
  const defaultExp = new Date();
  defaultExp.setTime(defaultExp.getTime() + (30 * 60 * 1000)); // rs session expires after 30 min by default

  const { rudderanalytics } = window;
  if (!rudderanalytics.getSessionId) return;

  const currentSessionId = rudderanalytics.getSessionId();
  Cookies.set('rs_session_id', currentSessionId, { expires: defaultExp, secure: true });
}

const trackEvent = (name, properties) => {
  const { rudderanalytics } = window;

  if (rudderanalytics) {
    rudderanalytics.track(name, properties);
  }
}

const renderRudderstackScript = () => {
  const rudderstackJsWriteKey = window.rudderstackEnvVars.write_key;
  const rudderstackDataPlaneUrl = window.rudderstackEnvVars.data_plane_url;
  const rudderstackAnonymousId = window.rudderstackEnvVars.anonymous_id;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){ var r=e.methods[t]; e[r]=e.factory(r)} e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0, r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},
  e.loadJS(),
  e.load("${rudderstackJsWriteKey}", "${rudderstackDataPlaneUrl}"),
  e.setAnonymousId("${rudderstackAnonymousId}")
}();`

  document.body.appendChild(script);
}

const RudderstackHelpers = {
  renderRudderstackScript,
  storeContextSessionId,
  trackEvent
}

export default RudderstackHelpers;
