const FormsNew = {
  selectMock() {
    $('.jsSelectMockToggle').on('click', function () {
      var $this = $(this),
        $selectMock = $this.closest('.jsSelectMock'),
        rand = Math.random();

      if ($selectMock.hasClass('active')) {
        $selectMock.removeClass('active');
        $(window).off(`click.selectMockToggle${rand}`);
      } else {
        $selectMock.addClass('active');

        // Next event loop so event doesn't propogate to this listener.
        window.setTimeout(() => {
          $(window).on(`click.selectMockToggle${rand}`, (e) => {
            $selectMock.removeClass('active');
            $(window).off(`click.selectMockToggle${rand}`);
          });
        }, 0);
      }
    });
  },
}
export default FormsNew;
