import ApplicationController from '../application_controller';

export default class CheckoutClickedController extends ApplicationController {
  static values = {
    cartId: String,
    location: String,
    products: Array,
    requestAction: String,
    requestController: String,
    state: String
  }

  initialize() {
    this.checkoutType = null;
  }

  properties() {
    return {
      cart_id: this.cartIdValue,
      checkout_type: this.checkoutType,
      location: this.locationValue,
      products: this.productsValue,
      request_action: this.requestActionValue,
      request_controller: this.requestControllerValue,
      state: this.stateValue
    };
  }

  track(event) {
    try {
      if (window.rudderanalytics) {
        this.checkoutType = event.currentTarget.dataset.rudderstackCheckoutType;
        const properties = this.properties();
        window.rudderanalytics.track('Checkout Clicked', properties);
      }
    } catch (error) {
      console.error('RS Checkout Error', error);
    }
  }
}
